<template>
  <footer class="bg-gray-900 z-50 text-white">
    <div class="mx-auto w-full">
      <div class="text-center p-10">
        <p class="font-semibold pb-2">Address</p>
        <p>
          Jl. Danau Sunter Utara No.7A, Sunter Agung, Kec. Tj. Priok, Kota Jkt
          Utara, Daerah Khusus Ibukota Jakarta 14350, Indonesia
        </p>
        <p>Telp : +62 811-8000-546</p>
      </div>
      <div
        class="px-10 py-6 bg-gray-900 flex text-center flex-col md:justify-between md:flex-row justify-center items-center gap-2"
      >
        <span class="text-sm text-gray-200 sm:text-center"
          >© 2024, PT. Dinamika Teknologi Jaya or its Affiliates™. All Rights
          Reserved.
        </span>
        <div
          class="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse"
        >
          <a
            href="https://imotoshare.com"
            target="_blank"
            rel="noopener noreferrer"
            class="text-gray-400 hover:text-gray-900"
          >
            <img
              src="https://imoto-web-asset.s3.ap-southeast-3.amazonaws.com/image/icons/imoto-share-logo.png"
              alt="imotoshare logo"
              class="w-10"
            />
            <span class="sr-only">imotoshare</span>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100082420362388"
            class="text-white hover:text-[##1877F2]"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              class="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 8 19"
            >
              <path
                fill-rule="evenodd"
                d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="sr-only">Facebook page</span>
          </a>
          <a
            href="https://www.instagram.com/imoto_indonesia/"
            class="text-white hover:text-[#833AB4]"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg
              class="w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 0C7.284 0 6.944 0.013 5.877 0.06 4.813 0.11 4.088 0.278 3.45 0.525c-0.657 0.255 -1.216 0.597 -1.771 1.154s-0.9 1.113 -1.154 1.771C0.278 4.088 0.109 4.813 0.06 5.877 0.01 6.944 0 7.284 0 10s0.013 3.056 0.06 4.123c0.05 1.064 0.217 1.79 0.465 2.427a4.875 4.875 0 0 0 1.154 1.771 4.875 4.875 0 0 0 1.771 1.154c0.639 0.246 1.364 0.416 2.427 0.465 1.066 0.05 1.406 0.06 4.123 0.06s3.056 -0.013 4.123 -0.06c1.064 -0.05 1.79 -0.219 2.427 -0.465a4.875 4.875 0 0 0 1.771 -1.154 4.875 4.875 0 0 0 1.154 -1.771c0.246 -0.637 0.416 -1.364 0.465 -2.427 0.05 -1.066 0.06 -1.406 0.06 -4.123s-0.013 -3.056 -0.06 -4.123c-0.05 -1.064 -0.219 -1.791 -0.465 -2.427a4.875 4.875 0 0 0 -1.154 -1.771A4.875 4.875 0 0 0 16.55 0.525c-0.637 -0.247 -1.364 -0.416 -2.427 -0.465C13.056 0.01 12.716 0 10 0m0 1.8c2.669 0 2.988 0.014 4.041 0.059 0.975 0.046 1.504 0.208 1.856 0.346 0.469 0.181 0.8 0.398 1.151 0.746 0.349 0.35 0.566 0.683 0.746 1.151 0.136 0.351 0.3 0.881 0.344 1.856 0.048 1.055 0.059 1.371 0.059 4.041s-0.013 2.988 -0.061 4.041c-0.051 0.975 -0.214 1.504 -0.351 1.856a3.125 3.125 0 0 1 -0.749 1.151 3.125 3.125 0 0 1 -1.15 0.746c-0.35 0.136 -0.887 0.3 -1.863 0.344 -1.061 0.048 -1.374 0.059 -4.049 0.059s-2.989 -0.013 -4.049 -0.061c-0.976 -0.051 -1.514 -0.214 -1.864 -0.351a3.125 3.125 0 0 1 -1.149 -0.749 3 3 0 0 1 -0.75 -1.15c-0.138 -0.35 -0.299 -0.887 -0.35 -1.863 -0.037 -1.05 -0.051 -1.374 -0.051 -4.036s0.014 -2.989 0.051 -4.051c0.051 -0.975 0.213 -1.511 0.35 -1.861 0.175 -0.475 0.399 -0.8 0.75 -1.151 0.349 -0.349 0.675 -0.574 1.149 -0.749 0.35 -0.139 0.876 -0.301 1.851 -0.351 1.063 -0.037 1.375 -0.05 4.049 -0.05zm0 3.065a5.135 5.135 0 1 0 0 10.27 5.135 5.135 0 1 0 0 -10.27m0 8.469a3.333 3.333 0 1 1 0 -6.667c1.843 0 3.334 1.491 3.334 3.334S11.843 13.334 10 13.334m6.539 -8.671a1.201 1.201 0 0 1 -2.4 0 1.2 1.2 0 0 1 2.4 0"
              />
            </svg>

            <span class="sr-only">Imoto Indonesia</span>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
